import React, { useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Modal} from 'react-bootstrap';
// import { BrowserRouter, Route, Switch, Link, useHistory} from 'react-router-dom';
// import { Button } from 'react-bootstrap';
// import { FaArrowUpFromBracket } from "react-icons/fa6";
import { FaDownload, FaUpload, FaRegTrashAlt, FaUndo} from "react-icons/fa";

// 07-03-2023 RJ 0.0.0 Display of the upcoming schedule of releases as a presentable table

import { download_file, from_ymd, nice_date, call_api, kmg, ConfirmationDialog } from "./Utils.jsx";
import { useKeys } from "./Auth.jsx";

import "./Sources.css";

// Show the source files used for a release with appropriate facilities
// for the user to download, upload, delete etc.

// const nhs_purple =  "#330072";
// const nhs_orange =  "#ed8b00";
// const nhs_green =   "#009639";

// const col_clin =    nhs_purple;
// const col_drugs =   nhs_orange;
// const col_path =    nhs_green;

// const pale_clin =    "#938bda";
// const pale_drugs =   "#f1a04d";
// const pale_path =    "#53b774";


/*eslint no-extend-native: ["error", { "exceptions": ["Date"] }]*/
Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

const Upload = ({isOpen, onClose, info, setMessage, triggerReload}) => {
    const [theFile, setTheFile] = useState();

    const say = (colour, message) => {
        setMessage(colour, message);
    }

    // console.log(info.current);
    const [category, release] = info.current;

    const handleUpload = async () => {
        const formData = new FormData();
        formData.append("file", theFile);
        formData.append("release", release);
        formData.append("category", category);

        say("purple", "Uploading...");

        // console.log(JSON.stringify(formData));

        call_api("/upload", formData)
        .then(result => {
            // console.log(result);
            const message = result.message;
            say("green", message);
            triggerReload();
        })
        .catch(error => {
            // console.log(error);
            say("red", 'Upload error: ' + error.message);
        })
        .finally(() => {
            setTheFile(undefined);
        })
    }

    const handleChange = (e) => {
        // console.log(e.type);
        e.preventDefault();
        // setIsReset(false);
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];

            setTheFile(file);
            // console.log(file);
        }
    }

    const dragging = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (e.type === "drop") {
            if (e.dataTransfer.files && e.dataTransfer.files[0]) {
                const files = e.dataTransfer.files;
                if (files.length === 1) {
                    setTheFile(files[0]);
                } else {
                    say("red", "I can't accept multiple files.");
                }
            } else {
                say("red", "I can only accept files.");
            }
        };
    };

    return (
        <Modal show={isOpen} onHide={onClose} centered size="lg">
            <Modal.Header closeButton>
            <Modal.Title>{category} upload</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form id="form-file-upload"
                    onDragEnter={dragging}
                    onDragLeave={dragging}
                    onDragOver={dragging}
                    onDrop={dragging}
                    onSubmit={(e) => e.preventDefault()}
                >
                    <table >
                        <tbody>
                            <tr>
                                <td><input className="choose-filename" type="text" value={theFile ? theFile.name : "Drop a file here"} readOnly/></td>
                                <td>
                                    <label className="choose-button" htmlFor="input-file-upload">Browse</label>
                                    <input type="file" id="input-file-upload" multiple={false} onChange={handleChange} />
                                </td>
                            </tr>
                            {theFile ?
                                <tr>
                                    <td></td>
                                    <td><Button className="upload-button" onClick={handleUpload}>Upload</Button></td>
                                </tr>
                            : null
                            }
                        </tbody>
                    </table>
                </form>
            </Modal.Body>
            <Modal.Footer>
                Browse for a file, then click upload to send it.
            </Modal.Footer>
        </Modal>
    )
}

function ErrorMessage(props) {
    const colour = props.colour;
    const message = props.message;

    // console.log(props);

    return <>
        <div className="error-message" style={{ "backgroundColor": colour }}>{message}</div>
    </>
}

function Action(props) {
    const Icon = props.icon;

    return <span className="source-action" style={{ "backgroundColor": props.colour }}>
        <Icon key={Icon} title={props.title} style={{ "backgroundColor": props.colour }} color="white" onClick={props.onClick} />
    </span>;
}

export function Sources(props) {
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [sources, setSources] = useState([]);
    const [message, setMessage] = useState(["white", ""]);

    const confirmYes = useRef();
    const confirmNo = useRef();
    const confirmMessage = useRef();

    const uploadInfo = useRef(["", ""]);

    const [uploadShowing, setUploadShowing] = useState(false);

    const keys = useKeys();
    // const [keys, setKeys] = useState();

    let params = useParams();
    let release = params.id;

    const showUpload = () => { setUploadShowing(true)};
    const unshowUpload = () => { setUploadShowing(false)};

    if (keys === undefined) {
        // getKeys(setKeys);
        return <div>Waiting for authorisation</div>;
    }

    const showMessage = (colour, message) => {
        setMessage([colour, message]);
    }

    const say = (colour, message) => {
        // Used only from the upload dialog
        showMessage(colour, message);
        unshowUpload();
    }

    const triggerReload = () => {
        setSources([]);
    }

    const handleUpload = (fileType) => {
        // console.log(`Uploading ${fileType}`);
        uploadInfo.current = [fileType, release];
        showUpload();
    };

    const handleDownload = (filename) => {
        download_file(`store/${release}/${filename}`);
        // console.log(`Would be downloading ${filename}`);
    };

    const openConfirmation = () => {
        setIsConfirmationOpen(true);
    };

    const closeConfirmation = () => {
        setIsConfirmationOpen(false);
    };

    const confirm = (message, onYes, onNo) => {
        confirmYes.current = onYes;
        confirmNo.current = onNo;
        confirmMessage.current = message;

        openConfirmation();
    };

    const reallyDelete = (action, category, filename) => {
        // console.log(`I'm really going to ${action} ${filename}`);
        call_api(`/source/${release}/${action}/${category}/${filename}`)
        .then (result => {
            // console.log(result);
            showMessage("green", result.message);
            triggerReload();
        })
        .catch (err => {
            // console.log(`Blimey - it all went wrong: ${err}`);
            showMessage("red", `${err.code}: ${err.message}`);
        })
        .finally (() => {
            triggerReload();
            closeConfirmation();
        });
    };

    const handleDelete = (category, filename) => {
        confirm(`Delete ${filename}?`, () => {reallyDelete("delete", category, filename)}, closeConfirmation);
    };

    const handleRevert = (category, filename) => {
        confirm(`Revert ${filename} to the default?`, () => {reallyDelete("revert", category, filename)}, closeConfirmation);
    };

    // const getSources = (source) => {
    if (! sources.length) {
        call_api(`/sources/${release}`)
        .then(data => {
            setSources(data);
        })
        .catch(e => {
            // console.log(e);
            const data = [
                {
                    "item": "No data",
                    "fileDate": "19630114",
                    "filename": "-",
                    "dl": 1,
                }
            ];
            setSources(data);
        });
    }

    // Render the table

    const rows = [];

    sources.forEach((entry) => {
        const et = from_ymd(entry.fileDate);

        const item = entry.item;        // We reference it so often
        var title;

        const icons = entry.opts?.map((icon, index) => {
            if (icon !== "dl" && keys.indexOf("upload") === -1)
                return <div/>;
            const gap = index ? <span key={icon + "gap"}> </span> : null;
            // console.log(index, gap, icon);
            var result;
            switch (icon) {
                case "ul":  title = entry.filename ? `Replace ${entry.filename} with another file` : `Upload a new ${item} file`;
                            result = <Action key={icon} icon={FaUpload} title={title} colour="#4466ff" onClick={() => handleUpload(item)} />;
                            break;
                case "un":  title = entry.revert ? `Revert to '${entry.revert}' ${item} file` : `Revert to the default ${item} file`;
                            result = <Action key={icon} icon={FaUndo} title={title} colour="#ffaa77" onClick={() => handleRevert(item, entry.filename)} />;
                            break;
                case "dl":  result = <Action key={icon} icon={FaDownload} title={`download the ${item} file`} colour="#22cc22" onClick={() => handleDownload(entry.filename)} />;
                            break;
                case "rm":  result = <Action key={icon} icon={FaRegTrashAlt} title={`Delete the ${item} file`} colour="#ff6666" onClick={() => handleDelete(item, entry.filename)}/>;
                            break;
                default:    return <div>{icon}</div>;
            }

            return <span key={index}>
                {gap}
                {result}
            </span>;
        });

        var colour;
        if (entry.upload_by) {
            title = `Uploaded by ${entry.upload_by} at ${entry.upload_time} on ${entry.upload_date}`;
            colour="blue"
        } else if (entry.filename) {
            colour="black"
            title = "original file";
        } else {
            colour="black"
            title = `Placeholder for ${item} file`;
        }

        rows.push(
            <tr key={entry.item + ":" + entry.filename}>
                <td key="item" style={{ "textAlign": "center", "whiteSpace": "nowrap"}} title={entry.tip}>{entry.item}</td>
                <td key="date" style={{ "textAlign": "center", }}>{nice_date(et)}</td>
                <td key="size" style={{ "textAlign": "right", }} title={entry.size ? `${entry.size.toLocaleString()} bytes` : ""}>{kmg(entry.size)}</td>
                <td key="filename" style={{ "textAlign": "left", "color":colour }} title={title}>{entry.filename}</td>
                <td key="options" style={{ "textAlign": "left" }}>{icons}</td>
            </tr>
        );
    })

    // console.log(message);
    return <div>
        <ErrorMessage colour={message[0]} message={message[1]} />
        <table className="source-table">
            <thead>
            <tr>
                <th>Item</th>
                <th>Date</th>
                <th>Size</th>
                <th>Filename</th>
                <th>Options</th>
            </tr>
            </thead>
            <tbody>
            {rows}
            </tbody>
        </table>
        <Upload
            info={uploadInfo}
            isOpen={uploadShowing}
            onClose={unshowUpload}
            setMessage={say}
            triggerReload={triggerReload}
        />
        <ConfirmationDialog
            isOpen={isConfirmationOpen}
            message={confirmMessage.current}
            onClose={confirmNo.current}
            onConfirm={confirmYes.current}
        />
    </div>
};
