import React, { useEffect, useState } from 'react';
import {
    useNavigate,
    useParams,
} from "react-router-dom";

import { FaCloudDownloadAlt } from 'react-icons/fa';
import { IoIosSettings } from "react-icons/io";

import { download_file, kmg, nice_date, call_api, to_ymd } from "./Utils.jsx";

import "./Products.css";

/// 07-03-2023 RJ 0.0.0 List products available for download

const nhs_purple =    "#330072";
const nhs_orange =    "#ed8b00";
const nhs_dpink =     "#7c2855";
const nhs_green =     "#009639";
// const nhs_blue =      "#005ebb";
// const nhs_yellow =    "#fae100";
// const nhs_lblue =     "#41b6e6";

// const nhs_emergency = "#da291c";

const col_clin =      nhs_purple;
const col_drugs =     nhs_orange;
const col_path =      nhs_green;
const col_int =       nhs_dpink;
// const col_edition =   nhs_blue;
// const col_xmap =      nhs_yellow;

// "international":    col_int,        # Reddish
// "clinical":         col_clin,       # Purple
// "drugs":            col_drugs,      # Orange
// "pathology":        col_path,       # Green
// "edition":          col_edition,    # Dark blue
// "crossmap":         col_xmap,       # Light brown

function colour_for_section(section) {
    section = section.toLowerCase();

    switch (section) {
        case "clinical":    return col_clin;
        case "drugs":       return col_drugs;
        case "pathology":   return col_path;
        case "other":       return col_int;
        default:            return col_int;
    }
    // return nhs_emergency;
}

class Product extends React.Component {
    render() {
        const item = this.props.item;
        const link = item.link;
        const file = link.split("/").splice(-1)[0];
        const style = (item.section === "Other") ? (this.props.pos & 1) ? {"float":"right"} : {"float":"left"} : {};

        // style={{"float":"left"}}
        return <div className="product-item" style={style}>
            <div title={"Created: " + item.created}>{item.title}</div>
            <div style={{"fontSize":"8pt"}}>({item.created})</div>
            {/* <hr/> */}
            <div
                className="spread hand"
                title={file}
            >
                {/* <a href={make_href(item.link)}> */}
                    <div>&nbsp;{kmg(item.size)}</div>
                {/* </a> */}
                <div onClick={() => download_file(item.link)}>
                    <FaCloudDownloadAlt
                    style={{fontSize: "20pt"}}
                    />
                    &nbsp;
                </div>
            </div>
        </div>
    }
};

class ProductColumn extends React.Component {

    render() {
        const title = this.props.columnTitle;
        const thetitle = <div style={{backgroundColor: colour_for_section(title)}} className="column-title">{title}</div>;
        const rows = [];

        var k = 0;
        this.props.items.forEach((item) => {
            rows.push(<Product item={item} pos={k} key={k}/>);
            k += 1;
        })

        return <div className="product-column" key={thetitle}>
            {thetitle}
            {rows}
        </div>
    }
};

export function ProductList(props) {
    const [data, setData] = useState([]);
    const navigate = useNavigate();
    let release = props.release

    console.log(release);
    useEffect(() => {
        call_api("/release-info/" + release)
        .then(data => setData(data))
        .catch((e) => setData("Could not get products: " + e.message))
    }, [release]);

    console.log(release);
    if (! release)
        return <div>Please select a release at the left.</div>

    console.log(data.constructor.name);
    if (data.constructor.name === "String")
        return <div>{data}</div>

    var tip;
    if (props.description) {
        tip = props.description;
    } else {
        if (release === "20991231") {
            tip = "All data including unversioned as taken from SnowOwl deltas";
        } else {
            tip = "Versioned data up to " + nice_date(release);
        }
    }
    const topdiv = (
        <div className="product-top">
            <IoIosSettings onClick={() => navigate(`/sources/${release}`)}/>
            <div className="product-tip" id="tip">
            {tip}
            </div>
            <div/>
        </div>
    );

    const rows = [];
    const sections = [];

    console.log(data);
    console.log(data.constructor.name);
    data.forEach((item) => {
        sections[item.section_order] = item.section;
    })

    sections.forEach((title, order) => {
        const column_data = [];

        data.forEach((item) => {
            if (item.section === title) {
                column_data.push(item)
            }
        })
        column_data.sort((a, b) => a.order - b.order)

        rows.push(<ProductColumn key={title} columnTitle={title} items={column_data} />);
    });

    return <div className="right-side">
        {topdiv}
        <div className="product-panel">
        {rows}
        </div>
    </div>
};

export function ShowProducts(props) {

    const [data, setData] = useState([]);

    useEffect(() => {
        call_api("/release-list")
        .then(data => setData(data))
        .catch(e => setData([{description: "No data", date: to_ymd(), version:e.message, count:1}]))
    }, []);

    const rows = [];

    let params = useParams();
    let navigate = useNavigate();
    let picked = params.id;
    var max_rows = 6;
    var description = "How did I get here?";

    data.forEach((datum) => {
        const d = datum.date;
        const version = datum.version;
        const count = datum.count;

        console.log(datum)
        if (count && max_rows-- > 0) {
            const td = nice_date(d);
            const link = d;

            if (picked === undefined && d !== "20991231")
                picked = link

            console.log(d, picked);
            const title = version + " (" + td + ")"
            var classname;

            if (link === picked) {
                description = datum.description;
                classname = "release-item-picked";
            } else {
                classname = "release-item-unpicked";
            }
            rows.push(
                <div className="release-list-item">
                    <span
                        className={classname}
                        key={d}
                        onClick={() => navigate("/releases/" + link, { replace: true })}
                        style={{ whiteSpace: 'nowrap' }}
                    >
                        {title}
                    </span>
                </div>
            );

        }
    })

    console.log(picked);
    if (! picked) {
        return <div className="release-screen">
            Awaiting small, lemon-soaked paper napkins...
        </div>
    }

    return <div className="release-screen">
        <div className="release-list">
            {rows}
        </div>
        <ProductList release={picked} description={description}/>
    </div>
}

