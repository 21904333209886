// Utilities for Arthur

// import { useState } from 'react';

import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import { getToken } from "./Auth.jsx";

export function make_href(filename) {
    // Returns a download link to a filename

    var here = new URL(window.location.href);
    here.pathname = "/download/" + filename;

    return "/download/" + filename;
}

export function download_file(filename) {
    call_api("/linkfor/" + filename)
        .then(data => download_link(data.link));
}

function download_link(path) {
    var here = new URL(window.location.href);
    here.pathname = "/download/" + path;

    var addr = here.href

    const link = document.createElement('a');
    link.href = addr;
    link.setAttribute(
      'download',
      path.split("/").pop(),
    );

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
}

export function kmg(size) {
    // Turn a number of bytes into a human readable form

    if (size === undefined)
        return "-";

    var suf = 0;
    while (size > 999) {
        size /= 1024;
        suf += 1;
    }

    const result = (size < 9.95 ? size.toFixed(1) : size.toFixed());
    return result + "BKMGTP"[suf];
}

class ApiError extends Error {
    constructor(code, message) {
        super(message);
        this.code = code;
    };

    toString() {
        return `${this.code}: ${this.message}`;
    }
}

export function call_api(url, data) {
    // Execute an API call against vroomfondel
    // If data is provided, the call will be a POST, otherwise a GET
    // A good result is always decoded JSON
    // Failure raises an ApiError exception with a 'code' and 'message'.
    // Use as:
    //      call_api("/someurl", optional_data)
    //      .then(data => do_something_with(data))
    //      .catch(e => console.log(`${e.code}: ${e.message}`)

    var method = "GET";
    var body = undefined;
    // console.log(data);
    if (data) {
        method = "POST";
        if (data.constructor.name === "FormData") {
            data.forEach((value, key) => {
                // console.log(`${key}: ${value}`);
            });
            // console.log(Array.from(data));

            // console.log(data);
            body = data;
        } else {
            // console.log(data);
            body = JSON.stringify(data);
        }
    }

    var response;

    // Do this to ensure we match https: when we are loaded as such
    // Otherwise the browser objects to pulling stuff from http:
    const origin = window.location.origin;
    const apiUrl = `${origin}/vroomfondel/${url}`;
    // console.log(apiUrl);
    return fetch(apiUrl, {
        method: method,
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            // 'Content-Type': 'application/json', // Uncomment if needed
            "Authorization": "Bearer " + getToken(),
        },
        body: body,
    })
    .then(res => {
        response = res;
        return response.json();
    })
    .then(json => {
        json.code = response.status;
        // console.log(json);
        if (!response.ok) {
            // console.log(`API FAIL (${url}): ${response.status} - ${response.statusText}`);
            throw new ApiError(json.code, json.detail);
        }

        return json;
    })
}

export function nice_date(d) {
    // Return a 'nice' date given either a yyyymmdd string or a Date()
    //
    // Returns:
    //   "Unversioned"  - Any date in 2099
    //   "1 Apr 2022"   - or similar for a 'normal' date
    if (typeof(d) == "string") {
        d = from_ymd(d);
    }

    const yy = d.getFullYear();
    const mm = d.getMonth();
    const dd = d.getDate();
    const mmm = "JanFebMarAprMayJunJulAugSepOctNovDec".slice(mm*3, mm*3+3);

    if (yy === 2099) {
        return "Unversioned";
    }

    return dd.toString() + " " + mmm + " " + yy.toString();
}

export function to_ymd(d) {
    // Convert a date to ymd, using 'today' if no date passed.
    if (!d) {
        d = new Date();
    }
    // console.log("D =", d);

    const yyyy = d.getFullYear().toString();
    const mm = (d.getMonth() + 1).toString().padStart(2, "0");
    const dd = d.getDate().toString().padStart(2, "0");;

    return yyyy + mm + dd;
}

export function from_ymd(ymd) {
    // Convert a date from YYYYMMDD format to a date

    if (ymd.length !== 8) {
        return new Date();
    }
    const y = Number(ymd.slice(0, 4));
    const m = Number(ymd.slice(4, 6));
    const d = Number(ymd.slice(6, 8));

    return new Date(y, m-1, d);
}

export function comma_list(array) {
    /* Join an array of strings into a single string with elements separated by commas,
     * and the word 'and' between the last two elements.
     *
     * @param {string[]} array - The array of strings to be joined.
     * @return {string} The joined string with elements separated by commas and 'and' before the last element.
     *
     * @throws Will throw an error if the input is not an array.
     */

    if (!Array.isArray(array)) throw new Error('Input must be an array of strings.');

    // Handle the special cases with 0, 1 or 2 elements
    const length = array.length;
    if (length === 0) return '';
    if (length === 1) return array[0];
    if (length === 2) return array.join(' and ')

    // Join all elements except the last one with commas and concatenate the last one with 'and'
    return `${array.slice(0, -1).join(', ')} and ${array[length - 1]}`;
  }


export const ConfirmationDialog = ({ isOpen, message, onClose, onConfirm }) => {
  return (
    <Modal show={isOpen} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Are you sure?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{message}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button key="cancel" variant="secondary" onClick={onClose}>Cancel</Button>
        <Button key="confirm" variant="primary" onClick={onConfirm}>Confirm</Button>
      </Modal.Footer>
    </Modal>
  );
};
