import React from 'react';
// import { BrowserRouter, Route, Switch, Link, useHistory} from 'react-router-dom';
// import { Button } from 'react-bootstrap';

// 07-03-2023 RJ 0.0.0 Display of the upcoming schedule of releases as a presentable table

import { from_ymd, nice_date, call_api, to_ymd, comma_list } from "./Utils.jsx";

import "./Schedule.css";

// Produce a nice display like (but better than) the schedule given at:
// https://hscic.kahootz.com/t_c_home/view?objectId=27703504
// https://nhsengland.kahootz.com/t_c_home/view?objectId=27703504
//
// Layout should be in columns with dates lined up, versions and release dates
// Also need notes such as (Major release including July 2021 International Edition)
// It's probably going to be best to use a table for this.

/*
const bgclass = {
    "Clinical": "clin-bg",
    "Drugs": "drug-bg",
    "Pathology": "path-bg",
    "UKCL": "clin-bg",
    "UKDR": "drug-bg",
    "UKPT": "path-bg",
};
*/

// const nhs_purple =  "#330072";
// const nhs_orange =  "#ed8b00";
// const nhs_green =   "#009639";

// const col_clin =    nhs_purple;
// const col_drugs =   nhs_orange;
// const col_path =    nhs_green;

const pale_clin =    "#938bda";
const pale_drugs =   "#f1a04d";
const pale_path =    "#53b774";


/*eslint no-extend-native: ["error", { "exceptions": ["Date"] }]*/
Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

export class Schedule extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            schedule: [],
        };

    }

    componentDidMount() {
        this.getSchedule();
    }

    getSchedule() {
        call_api("/schedule")
        .then(data => {
            this.setState({"schedule": data});
        })
        .catch(e => {
            const fallbackData = [
                {
                    "effectiveTime": to_ymd(),
                    "releaseDate": to_ymd(),
                    "version": "-",
                    "SI": "",
                    "output": [],
                    "notes": "No data: " + e.message
                }
            ];
            this.setState({"schedule": fallbackData})
        });
    }

    Pill(title, colour) {
        return <div className="release-pill" style={{ "backgroundColor": colour }}> {title} </div>;
        // style={{ "backgroundColor": col_clin }}
    }

    render() {

        // Render the table
        // We'll bold the row that represents the next publication

        const rows = [];
        var last_si = "";
        const today = new Date();  // Today's date as yyyymmdd
        var past_today = false;
        const start_at = today.addDays(-90);

        this.state.schedule.forEach((entry) => {
            const et = from_ymd(entry.effectiveTime);
            const publish_date  = from_ymd(entry.releaseDate);
            const publish       = nice_date(publish_date);
            console.log(entry);
            const clinical      = entry.output.includes("clinical") ? this.Pill("Clinical", pale_clin)   : "";
            const drugs         = entry.output.includes("drugs") ? this.Pill("Drugs", pale_drugs)     : "";
            const si            = entry.SI;
            var weight = "normal";

            var release_type = "Unknown";
            var including = [];

            if (si !== last_si) {
                release_type = "Major";
                including.push(nice_date(si) + " International Edition");
                last_si = si;
            } else {
                release_type = "Minor";
            }

            if (!past_today) {
                if (publish_date >= today) {
                    past_today = true;
                    weight = "bold";
                    release_type = "Next";
                }
            }

            if (entry.input.includes("refsets")) including.push("Refsets");
            if (entry.input.includes("crossmaps")) including.push("Crossmaps");

            if (publish_date < start_at) {
                return;
            }

            var notes = release_type + " release";
            if (including.length) {
                notes += " including " + comma_list(including);
            }
            if (notes == "Minor release") notes = "";   // gets tedious with lots of just saying 'Minor release'

            rows.push(
                <tr key={entry.effectiveTime} style={{ "fontWeight": weight }}>
                    <td style={{ "textAlign": "center" }}>{entry.version}</td>
                    <td className="center-text courier-font">{publish}</td>
                    <td className="center-text courier-font">{entry.effectiveTime}</td>
                    <td className="left-text">{clinical} {drugs}</td>
                    <td>{notes}</td>
                </tr>
            );
        })

        return <div>
            <table className="sched-table">
                <thead>
                    <tr>
                        <th>Version</th>
                        <th>Release Date</th>
                        <th>EffectiveTime</th>
                        <th>Release Type</th>
                        <th>Notes</th>
                    </tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </table>
        </div>
    }
};
