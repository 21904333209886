import React, { useState, useRef } from 'react';

import PropTypes from 'prop-types';
import './Auth.css';

import { call_api} from "./Utils.jsx";

import { AiOutlineExclamationCircle } from "react-icons/ai";
// import { Children } from 'react';

/*
    We keep two versions of the authentication token:
        localStorage        has the actual JWT as returned from vroomfondel/login
        sessionStorage      has the decoded payload as returned from vroomfondel/authenticate
*/

// import { useEffect, useState } from 'react';

// This comes from https://stackoverflow.com/questions/72158797/how-to-display-a-spinner-when-data-is-loading-from-server-by-fetch-api-in-react

// export default function Home() {
//     const [loading, setLoading] = useState(false);

//     useEffect(() => {
//         setLoading(true);
//         fetch('/api/hello')
//             .then((res) => res.json())
//             .then((data) => {
//                 // do something with data
//             })
//             .catch((err) => {
//                 console.log(err);
//             })
//             .finally(() => {
//                 setLoading(false);
//             });
//     }, []);

//     if (loading) {
//         return <LoadingComponent />;
//     }

//     return <MyRegularComponent />;
// }

export function useAllowed(keys) {
    // Ascertain whether the user has the required keys
    const info = useKeys();

    // console.log("useAllowed(",keys,"): ",info)
    return !keys || (info && info.includes(keys));
}

// export function CheckAllowed(keys) {
//     // Check that the user is allowed to perform an action

//     // Returns a promise

//     // CheckAllowed("upload")
//     //      .then(doSomething)
//     //      .catch(sayNotAllowed)
//     return Promise((resolve, reject) => {
//         if (getKeys()) {
//             resolve();
//         } else {
//             reject();
//         }
//     })
// }

export function Need(props) {
    // Wrapper element that displays its children only if
    // props.keys is included in user's keys
    const keys = props.keys;
    const children = props.children;

    if (useAllowed(keys)) {
        return <div style={{ height: "100%", width: "100%" }}>{children}</div>;
    } else {
        return <div>Not allowed</div>
    }
}




// Spend too long trying to get useKeys() to do what I want.
// It should call call_api("/getkeys") just once, caching the
// result.
// Calling code should just be able to use: keys = useKeys();


export function useKeys() {
    const initialised = useRef(false);
    const [keys, setKeys] = useState();

    // console.log(initialised.current, keys);
    if (! initialised.current) {
        initialised.current = true;

        // console.log(initialised.current, keys);
        call_api("/getkeys")
            .then((newkeys) => {setKeys(newkeys)});
    }

    // console.log(initialised.current, keys);
    return keys;
}

// export function useKeys() {
//     const [keys, setKeys] = useState();
//     const [pending, setPending] = useState(false);

    // console.log(pending, keys);
//     if (! pending && keys === undefined) {
//         console.log(pending, keys);

//         setPending(true);
//         console.log(pending, keys);
//         call_api("/getkeys")
//         .then((keys) => {setKeys(keys)});
//     }

//     console.log(pending, keys);
//     return keys;
// }

// export async function useKeys() {
//     const [keys, setKeys] = useState();

//     if (keys === undefined) {
//         try {
//             setKeys(await call_api("/getkeys"));
//         } catch {
//             setKeys([]);
//         }
//     }

//     return keys;
// }

// export function useKeys() {
//     const [keys, setKeys] = useState();

//     console.log("Entering useKeys()");
//     useEffect(() => {
//         console.log("Entering useEffect of useKeys");
//         const fetchKeys = async () => {
//             try {
//                 const data = await call_api("/getkeys");
//                 setKeys(data);
//             } catch (e) {
//                 console.log(`Error on /getkeys: {e.message}`);
//                 setKeys([]);
//             }
//         };

//         console.log("Before fetchKeys()", keys);
//         fetchKeys();
//         console.log("After fetchKeys()", keys);
//     }, []);

//     console.log("Midway keys", keys);

//     // useEffect(() => {
//     //     call_api("/getkeys")
//     //     .then((newkeys) => {
//     //         console.log(">>>>>>>>>>>>>>>>>> Setting keys:", newkeys);
//     //         setKeys(newkeys);
//     //     })
//     //     .catch(() => {
//     //         console.log("===================  Error getting keys");
//     //         setKeys([]);
//     //     })
//     // }, []);

//     return keys;
// }

// export function useKeys() {
//     // If we don't already have the user's keys, fetch
//     // them from the API.
//     const [keys, setKeys] = useState();

//     console.log("---->", keys);
//     if (keys === undefined) {
//         call_api("/getkeys")
//         .then((newkeys) => {
//             console.log(">>>>>>>>>>>>>>>>>> Setting keys:", newkeys);
//             setKeys(newkeys);
//         })
//         .catch(() => {
//             console.log("===================  Error getting keys");
//             setKeys([]);
//         })
//     }

//     return keys;
// }

// export function useKeys() {
//     // Use the initial value function to set keys only if it's undefined
//     const [keys, setKeys] = useState(() => {
//         const storedKeys = localStorage.getItem('keys'); // Use sessionStorage or localStorage as needed
//         return storedKeys ? JSON.parse(storedKeys) : null;
//     });

//     useEffect(() => {
//         // If keys are not available, fetch them from the API
//         if (!keys) {
//             call_api("/getkeys")
//                 .then((newKeys) => {
//                     console.log(">>>>>>>>>>>>>>>>>> Setting keys:", newKeys);
//                     setKeys(newKeys);
//                     localStorage.setItem('keys', JSON.stringify(newKeys)); // Store keys in sessionStorage or localStorage
//                 })
//                 .catch(() => {
//                     console.log("===================  Error getting keys");
//                     setKeys([]);
//                 });
//         }
//     }, [keys]);

//     return keys;
// }


export function useUser() {
    var [info, setInfo] = useState();

    if (!info) {
        var token = getToken();

        if (!token) {      // Not set so will need to login
            return [info, setInfo];
        }

        const user_info = authenticateToken(token);
        info = user_info;
    }

    return [info, setInfo];
}

function authenticateToken(token) {
    // Simple, trusting decoding of the token
    try {
        const parts = token.split(".");
        if (parts.length !== 3) {
            return null;
        }
        const payload = atob(parts[1]);
        const user = JSON.parse(payload);

        return user;
    } catch (e) {
        console.error(`Error - ${e.name}: ${e.message}`);
        return null;
    };
}

function setLocalKeys(keys) {
    // Set the user keys into local storage (only used for UX!)

    localStorage.setItem("keys", keys);
}

export function getKeys(setter) {
    // Get the user keys
    // Call as:
    // const [keys, setKeys] = useState();
    // ...
    // getKeys(setKeys);

    call_api("/getkeys")
    .then((keys) => {
        return setter(keys);
    })
    .catch(() => {
        return setter([]);
    })
}

export function setToken(token) {
    // Set the token passed into local storage

    localStorage.setItem("token", token);
    setUserInfo({});
}

export function getToken() {
    // Get the token from local storage
    // If there is none or it's not a valid abc.def.ghi string, return ""
    const token = localStorage.getItem("token");
    if (typeof (token) !== "string" || token.split(".").length !== 3)
        return "";
    return token;
}

export function setUserInfo(info) {
    /* Set userinfo to the object passed
    */
    sessionStorage.setItem("user_info", JSON.stringify(info));
}

export function getUserInfo() {
    var token = getToken();

    if (!token) {
        return undefined;
    }

    var user_info = null;

    var user_info_str = sessionStorage.getItem("user_info");
    if (typeof (user_info_str) != "string") {
        user_info_str = "";
    }

    if (user_info_str) {
        user_info = JSON.parse(user_info_str);
    }

    if (!user_info || Object.keys(user_info).length === 0) {
        user_info = authenticateToken(token);
        setUserInfo(user_info);
    }

    return user_info;
}

export function deleteToken() {
    localStorage.removeItem("token");
}

function forgotPassword(setMessage, username) {
    // Called when user clicks the 'forgotten password' button

    // console.log("Forgotten");
    // console.log(window.location);
    // console.log({ "username": username, "url": window.location.origin });
    // console.log(JSON.stringify({ "username": username, "url": window.location.origin }));

    if (! username) {
        setMessage("Please enter your id first");
        return;
    }

    call_api('/reset-password', {"username": username, "url": window.location.origin + "/password-reset"})
    .then(resp => {
        if (resp["ok"]) {
            setMessage(resp["result"]);
        } else {
            setMessage("Can't reset password: " + resp["error"]);
        }
    });
}

async function resetPassword(uuid, password) {
    // Return a promise that yields a success story or otherwise
    return call_api('/set-password', {"uuid": uuid, "password": password})
}

export function PasswordReset(props) {
    // We arrive here when the user has clicked on a link they've received in an email
    // There is no token here as they're not logged in.
    // We get their username from vroomfondel then prompt for a password twice
    // Check that it's >= 8 chars and both the same, then send that with the uuid to
    // vroomfondel for the change to be done.

    const [username, setUsername] = useState();
    const [password1, setPassword1] = useState();
    const [password2, setPassword2] = useState();
    const [message, setMessage] = useState();
    const [disabled, setDisabled] = useState(false);

    const parts = window.location.pathname.split("/")
    if (parts.length <= 2)
        return <div>Invalid {parts.length}</div>;
    var uuid = parts[parts.length - 1];
    if (uuid === "")
        uuid = parts[parts.length - 2];

    if (!username) {    // Not yet identified to chat to vroomfondel
        if (message)
            return <div className="reset-error">{message}</div>

        call_api("/check-password-uuid/" + uuid)
        .then(data => setUsername(data.username))
        .catch(e => setMessage(e.message))

        return <div className="reset-wait">Please wait - validating request...</div>
    }

    const handleSubmit = async e => {
        e.preventDefault();
        if (password1 !== password2) {
            setMessage("Passwords don't match");
            return;
        }
        if (password1.length < 8) {
            setMessage("Passwords must be at least 8 characters");
            return;
        }

        setDisabled(true);
        resetPassword(uuid, password1)
        .then(result => {
            setMessage(result.result + " - You'll be redirected in five seconds");
            setTimeout(function(){
                window.location.href = window.location.origin;
            }, 5000);
        })
        .catch(e => {
            setMessage("Password not reset: " + e.message);
            setDisabled(false);
        })

        // const result = await resetPassword(uuid, password1);
        // if (result.code === 200) {
        //     setMessage(result.result + " - You'll be redirected in five seconds");
        //     setTimeout(function(){
        //         window.location.href = window.location.origin;
        //      }, 5000);
        // } else if (result.code === 406) {
        //     setMessage("Password not reset: " + result.reason);
        //     setDisabled(false);
        // } else {
        //     setMessage("Password could not be reset: " + result.reason + " - no point trying again... ");
        // }
    }

    return (
        <div className="login-screen">
            <form onSubmit={handleSubmit}>
                <div >
                    <h1 className="login-title">Password reset for {username}</h1>
                    {message}
                    <div className="login-prompts login-password">Password:</div>
                    <div className="login-values login-password">
                        <input name="password1" type="password" required={true} onChange={e => setPassword1(e.target.value)} />
                    </div>
                    <div className="login-prompts login-password">Again:</div>
                    <div className="login-values login-password">
                        <input name="password2" type="password" required={true} onChange={e => setPassword2(e.target.value)} />
                    </div>
                    <div className="login-submit">
                        <button type="submit" disabled={disabled}>Reset Zarquon password</button>
                    </div>
                </div>
            </form>
        </div>
    );
}

async function loginUser(username, password) {
    // Return a promise that yields a token or ""
    return call_api('/login', {"username": username, "password": password})
}

export function Logout() {
    setLocalKeys("");
    deleteToken();
}

export function Login(props) {
    const [username, setUserName] = useState();
    const [password, setPassword] = useState();
    const [message, setMessage] = useState();

    const setUser = props.setUser;

    const handleSubmit = async e => {
        e.preventDefault();
        const loginDetails = await loginUser(username, password);
        // console.log("loginDetails", loginDetails)
        const keys = loginDetails.keys;
        // console.log("Setting keys", keys)
        setLocalKeys(keys);

        const token = loginDetails.token;
        if (!token) {
            setMessage(<h2 style={{ color: "red" }}><AiOutlineExclamationCircle /> Incorrect email or password <AiOutlineExclamationCircle /></h2>)
            setPassword("")
        }
        setToken(token);
        setUser(getUserInfo());
    }

    return (
        <div className="login-screen">
            <form onSubmit={handleSubmit}>
                <div className="login-grid">
                    <div className="login-title">Don't Panic!</div>
                    <div className="login-message">{message}</div>
                    <div className="login-prompts login-email">Email or id:</div>
                    <div className="login-values login-email">
                        <input name="username" type="text" required={true} placeholder="your.name@nhs.net" onChange={e => setUserName(e.target.value)} />
                    </div>
                    <div className="login-prompts login-password">Password:</div>
                    <div className="login-values login-password">
                        <input name="password" type="password" required={true} autoComplete="current-password" onChange={e => setPassword(e.target.value)} />
                    </div>
                    <div className="login-submit">
                        <button type="submit">Login to Zarquon</button>
                    </div>
                    <div/><div/>
                    <div className="login-forgot" onClick={e => forgotPassword(setMessage, username)}>forgot password?</div>

                </div>
            </form>
        </div>
    );
}

Login.propTypes = {
    setUser: PropTypes.func.isRequired
};